.share {
  div {
    margin: 1em 0;
  }

  textarea {
    width: 40ex;
    height: 5em;
    border: 0;
  }
}
