.video-details {
  .properties {
    dt {
      width: 33.3%;
    }

    dd {
      width: 66.7%;
    }
  }
}
