.modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $accent-color-2;
  color: $background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include respond('padding', $r-space-large);

  a {
    color: $background-color;
    border-color: $background-color;
  }

  nav {
    position: absolute;
    left: 0;
    width: 100%;
    @include respond('bottom', $r-space-large);

    a {
      margin: 0 auto;
      background: $background-color;
      color: $accent-color-2;
    }
  }

  button {
    background: $background-color;
    color: $accent-color-2;
    border: 0;
  }

  .spacer {
    @include respond('height', $r-space-medium);
  }
}
